import { Form, useSelector } from '@innedit/formidable';
import { EvenementModel } from '@innedit/innedit-react';
import { PageProps } from 'gatsby';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../components/Button';
import Content from '../components/Content';
import Layout from '../components/Layout';
import newsletter from '../params/newsletter.json';
import { StateProps } from '../reducers';

const PageFrNewsletter: FC<PageProps> = function ({ location: { pathname } }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isSend, setIsSend] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const { user: stateUser } = useSelector((state: StateProps) => state);
  const authUser = stateUser ? stateUser.authUser : undefined;
  const user = stateUser && stateUser.item ? stateUser.item : undefined;

  const handleOnSubmit = (values: any) => {
    setIsPending(true);

    EvenementModel.create({
      data: {
        object: {
          ...values,
          language,
          boutique: process.env.GATSBY_ID_BOUTIQUE,
          channel: process.env.GATSBY_ID_CHANNEL,
        },
      },
      type: 'newsletter.registered',
      userId: authUser?.uid,
    })
      .then(() => {
        setIsPending(false);
        setError(undefined);

        return setIsSend(true);
      })
      .catch(({ message }) => {
        setIsPending(false);
        setIsSend(false);
        setError(message);
      });
  };

  if (isSend && !error) {
    return (
      <Layout pathname={pathname} showTitle title="pages.newsletter.title">
        <Content className="flex flex-col p-6 max-w-screen-sm w-full mx-auto">
          <p className="text-center">{t('pages.newsletter.demande-envoyee')}</p>
          <p className="text-center">{t('pages.newsletter.vous-recevrez')}</p>
          <p className="mt-12 text-center">{t('infos.dealer.name')}</p>
        </Content>
      </Layout>
    );
  }

  return (
    <Layout pathname={pathname} showTitle title="pages.newsletter.title">
      <Content className="flex flex-col p-6 max-w-screen-sm w-full mx-auto">
        <p className="text-center mb-12">
          {t('pages.newsletter.vous-pouvez-vous-inscrire')}
        </p>
        <Form
          datas={newsletter}
          enableReinitialize
          footerProps={{ className: 'flex !justify-center my-6' }}
          hideSubmitButton
          initialValues={user}
          isSubmissive={!isPending}
          name="contact"
          onSubmit={handleOnSubmit}
        >
          {error && (
            <div className="flex flex-col justify-center mt-2">
              <span className="text-center text-danger-500 text-sm">
                {error}
              </span>
            </div>
          )}
          <div className="flex mt-12 items-center justify-center">
            <Button loading={isPending} type="submit">
              {t('form.newsletter.submit.label')}
            </Button>
          </div>
          {isPending && (
            <div className="flex flex-col justify-center mt-2">
              <span className="text-center text-secondary-500 text-sm">
                {t('pages.newsletter.is-pending')}
              </span>
            </div>
          )}
        </Form>
      </Content>
    </Layout>
  );
};

export default PageFrNewsletter;
